<template>
  <div class="flex items-center" :class="{ 'pointer-events-none opacity-50' : disabled }">
    <div
      class="border relative rounded-full w-16 h-[34px] transition duration-200 ease-linear focus-within:outline outline-2 outline-offset-2 outline-action"
      :class="[_value ? `bg-action` : `bg-secondary`]"
    >
      <label
        :for="id"
        class="absolute left-0 bg-primary mb-2 w-8 h-8 border-2 rounded-full transition transform duration-100 ease-linear cursor-pointer"
        :class="[_value ? ['translate-x-full', `border-action bg-white/70`] : ['translate-x-0', `border-secondary bg-primary`]]"
      />
      <input
        :id="id"
        v-model="_value"
        :aria-checked="_value"
        type="checkbox"
        name="toggle"
        class="appearance-none w-full h-full active:outline-none focus:outline-none"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'BToggle',
  props: {
    value: {
      type: Boolean,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      id: this._uid
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  }
}
</script>

<style>
  .b-checkbox:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from './components/nuxt-error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_a913e632 from 'nuxt_plugin_sentryserver_a913e632' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_45bc9b5f from 'nuxt_plugin_sentryclient_45bc9b5f' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_7687cc6a from 'nuxt_plugin_cookieuniversalnuxt_7687cc6a' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_vuescrollto_8041eef4 from 'nuxt_plugin_vuescrollto_8041eef4' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_pluginutils_47598f10 from 'nuxt_plugin_pluginutils_47598f10' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_6b30f07a from 'nuxt_plugin_pluginrouting_6b30f07a' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_1e75328c from 'nuxt_plugin_pluginmain_1e75328c' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_axios_826dbfba from 'nuxt_plugin_axios_826dbfba' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_debug_ebe8e00c from 'nuxt_plugin_debug_ebe8e00c' // Source: ./Ovatu/debug.js (mode: 'all')
import nuxt_plugin_index_2ad46070 from 'nuxt_plugin_index_2ad46070' // Source: ./Ovatu/components/index.js (mode: 'all')
import nuxt_plugin_portalvue_5fb12be0 from 'nuxt_plugin_portalvue_5fb12be0' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_intl_5b870e44 from 'nuxt_plugin_intl_5b870e44' // Source: ../plugins/intl.js (mode: 'all')
import nuxt_plugin_site_63f9f51a from 'nuxt_plugin_site_63f9f51a' // Source: ../plugins/site.js (mode: 'all')
import nuxt_plugin_format_4869d82c from 'nuxt_plugin_format_4869d82c' // Source: ../plugins/format.js (mode: 'all')
import nuxt_plugin_prefill_39d99187 from 'nuxt_plugin_prefill_39d99187' // Source: ../plugins/prefill.js (mode: 'all')
import nuxt_plugin_render_45111c2a from 'nuxt_plugin_render_45111c2a' // Source: ../plugins/render.js (mode: 'all')
import nuxt_plugin_components_50cb0b6b from 'nuxt_plugin_components_50cb0b6b' // Source: ../plugins/components.js (mode: 'all')
import nuxt_plugin_api_785206da from 'nuxt_plugin_api_785206da' // Source: ../plugins/api.js (mode: 'all')
import nuxt_plugin_floating_4de0e685 from 'nuxt_plugin_floating_4de0e685' // Source: ../plugins/packages/floating.js (mode: 'all')
import nuxt_plugin_fontawesome_1af27346 from 'nuxt_plugin_fontawesome_1af27346' // Source: ../plugins/packages/font-awesome.js (mode: 'all')
import nuxt_plugin_moment_d092116a from 'nuxt_plugin_moment_d092116a' // Source: ../plugins/packages/moment.js (mode: 'all')
import nuxt_plugin_tippy_c7b99c82 from 'nuxt_plugin_tippy_c7b99c82' // Source: ../plugins/packages/tippy.js (mode: 'all')
import nuxt_plugin_googleanalytics_bcfd0bce from 'nuxt_plugin_googleanalytics_bcfd0bce' // Source: ../plugins/packages/google-analytics.js (mode: 'client')
import nuxt_plugin_facebookpixel_73a8b7a8 from 'nuxt_plugin_facebookpixel_73a8b7a8' // Source: ../plugins/packages/facebook-pixel.js (mode: 'client')
import nuxt_plugin_i18n_64c0676d from 'nuxt_plugin_i18n_64c0676d' // Source: ../plugins/packages/i18n.js (mode: 'client')
import nuxt_plugin_vcalendar_1e0c5476 from 'nuxt_plugin_vcalendar_1e0c5476' // Source: ../plugins/packages/v-calendar.js (mode: 'client')
import nuxt_plugin_googlemaps_3ae50598 from 'nuxt_plugin_googlemaps_3ae50598' // Source: ../plugins/packages/google-maps.js (mode: 'client')
import nuxt_plugin_vuegallery_512a3138 from 'nuxt_plugin_vuegallery_512a3138' // Source: ../plugins/packages/vue-gallery.js (mode: 'client')
import nuxt_plugin_rating_47d5c68e from 'nuxt_plugin_rating_47d5c68e' // Source: ../plugins/packages/rating.js (mode: 'client')
import nuxt_plugin_plugin_f99b71c2 from 'nuxt_plugin_plugin_f99b71c2' // Source: ./auth/plugin.js (mode: 'all')
import nuxt_plugin_localstorage_c53c9e50 from 'nuxt_plugin_localstorage_c53c9e50' // Source: ../auth/plugins/localstorage.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"minisite-nuxt","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0"},{"name":"format-detection","content":"telephone=no"},{"name":"mobile-web-app-capable","content":"yes"},{"name":"apple-mobile-web-app-capable","content":"yes"},{"name":"msapplication-starturl","content":"\u002F"}],"link":[{"rel":"manifest","href":"\u002Fmanifest.json"}],"script":[],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_a913e632 === 'function') {
    await nuxt_plugin_sentryserver_a913e632(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_45bc9b5f === 'function') {
    await nuxt_plugin_sentryclient_45bc9b5f(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_7687cc6a === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_7687cc6a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_8041eef4 === 'function') {
    await nuxt_plugin_vuescrollto_8041eef4(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_47598f10 === 'function') {
    await nuxt_plugin_pluginutils_47598f10(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_6b30f07a === 'function') {
    await nuxt_plugin_pluginrouting_6b30f07a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_1e75328c === 'function') {
    await nuxt_plugin_pluginmain_1e75328c(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_826dbfba === 'function') {
    await nuxt_plugin_axios_826dbfba(app.context, inject)
  }

  if (typeof nuxt_plugin_debug_ebe8e00c === 'function') {
    await nuxt_plugin_debug_ebe8e00c(app.context, inject)
  }

  if (typeof nuxt_plugin_index_2ad46070 === 'function') {
    await nuxt_plugin_index_2ad46070(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_5fb12be0 === 'function') {
    await nuxt_plugin_portalvue_5fb12be0(app.context, inject)
  }

  if (typeof nuxt_plugin_intl_5b870e44 === 'function') {
    await nuxt_plugin_intl_5b870e44(app.context, inject)
  }

  if (typeof nuxt_plugin_site_63f9f51a === 'function') {
    await nuxt_plugin_site_63f9f51a(app.context, inject)
  }

  if (typeof nuxt_plugin_format_4869d82c === 'function') {
    await nuxt_plugin_format_4869d82c(app.context, inject)
  }

  if (typeof nuxt_plugin_prefill_39d99187 === 'function') {
    await nuxt_plugin_prefill_39d99187(app.context, inject)
  }

  if (typeof nuxt_plugin_render_45111c2a === 'function') {
    await nuxt_plugin_render_45111c2a(app.context, inject)
  }

  if (typeof nuxt_plugin_components_50cb0b6b === 'function') {
    await nuxt_plugin_components_50cb0b6b(app.context, inject)
  }

  if (typeof nuxt_plugin_api_785206da === 'function') {
    await nuxt_plugin_api_785206da(app.context, inject)
  }

  if (typeof nuxt_plugin_floating_4de0e685 === 'function') {
    await nuxt_plugin_floating_4de0e685(app.context, inject)
  }

  if (typeof nuxt_plugin_fontawesome_1af27346 === 'function') {
    await nuxt_plugin_fontawesome_1af27346(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_d092116a === 'function') {
    await nuxt_plugin_moment_d092116a(app.context, inject)
  }

  if (typeof nuxt_plugin_tippy_c7b99c82 === 'function') {
    await nuxt_plugin_tippy_c7b99c82(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleanalytics_bcfd0bce === 'function') {
    await nuxt_plugin_googleanalytics_bcfd0bce(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_facebookpixel_73a8b7a8 === 'function') {
    await nuxt_plugin_facebookpixel_73a8b7a8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_i18n_64c0676d === 'function') {
    await nuxt_plugin_i18n_64c0676d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vcalendar_1e0c5476 === 'function') {
    await nuxt_plugin_vcalendar_1e0c5476(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googlemaps_3ae50598 === 'function') {
    await nuxt_plugin_googlemaps_3ae50598(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuegallery_512a3138 === 'function') {
    await nuxt_plugin_vuegallery_512a3138(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_rating_47d5c68e === 'function') {
    await nuxt_plugin_rating_47d5c68e(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_f99b71c2 === 'function') {
    await nuxt_plugin_plugin_f99b71c2(app.context, inject)
  }

  if (typeof nuxt_plugin_localstorage_c53c9e50 === 'function') {
    await nuxt_plugin_localstorage_c53c9e50(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }

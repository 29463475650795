<template>
  <div class="h-full w-screen bg-secondary flex flex-col">
    <a href="#content" class="fixed -left-full z-[5000] p-2 m-6 rounded border-white bg-black text-white focus:left-1/2 focus:-translate-x-1/2">Skip to main content</a>

    <AppBar />

    <div id="main" class="relative overflow-y-auto overflow-x-hidden flex-grow flex-shrink sm:pt-20">
      <Navigation />

      <slot />

      <Footer/>
    </div>

    <portal-target name="layout" multiple>

    </portal-target>

    <Mobile />

    <Menu />

    <client-only>
      <Cookie v-if="$site.settings.showCookieConsent" layout="site" />
    </client-only>

    <div id="bookappCustomScriptBody" class="hidden" />
  </div>
</template>

<script>
import Navigation from './elements/Navigation'
import Footer from './elements/Footer'

import AppBar from './elements/AppBar'
import Mobile from './elements/Mobile'
import Menu from './elements/Menu'

import Cookie from './elements/Cookie'

export default {
  components: {
    Navigation,
    Footer,
    AppBar,
    Mobile,
    Menu,
    Cookie
  },
  head () {
    const bookappCustomScriptHead = this?.$site?.settings?.bookappCustomScriptHead || ''

    return {
      htmlAttrs: {
        class: 'layout-default'
      },
      script: [
        {
          type: 'text/javascript',
          innerHTML: bookappCustomScriptHead
        }
      ],
      __dangerouslyDisableSanitizers: ['script']
    }
  },
  mounted () {
    if (this.$site.settings.bookappCustomScriptBody) {
      const postscribe = require('postscribe')
      postscribe('#bookappCustomScriptBody', this.$site.settings.bookappCustomScriptBody)
    }
  },
  watch: {
    $route (to, from) {
      const el = document.getElementById('main')
      el?.scrollTo({ top: 0 })
    }
  }
}
</script>

<style>
html.layout-default {
  height: -webkit-fill-available;
  height: 100%
}

.layout-default body {
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  height: 100%
}

.layout-default #__nuxt {
  height: 100%
}

.layout-default #__layout {
  height: 100%
}

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}
</style>

<template>
  <div class="text-center mb-12 mt-12">
    <h2 class="relative z-10">{{ title }}</h2>

    <p class="relative z-10">{{ info }}</p>

    <div v-if="items" class="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 gap-6">
      <div
        v-for="employee in items" :key="employee.id"
        class="text-center block"
      >
        <div v-if="images" class="mb-3">
          <img
            v-if="employee.images" :src="employee.images.medium" :alt="employee.name"
            class="mx-auto w-52 h-52 object-cover rounded"
          />

          <div v-else class="mx-auto w-52 h-52 bg-action text-action rounded flex items-center justify-center">
            <p class="text-3xl font-bold">
              {{
                employee.displayName
                  .charAt(0)
                  .toUpperCase()
              }}
            </p>
          </div>
        </div>

        <div>
          <h5 class="font-semibold">{{ employee.displayName }}</h5>

          <p class="text-sm opacity-80 line-clamp-3 overflow-hidden">
            {{ employee.bio }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BookingStage, createBookingRoute } from '@/components/booking/elements/Detail.vue'

export default {
  name: 'EmployeesBlock',
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    info: {
      type: String,
      default: 'Info'
    },
    employees: {
      type: [Array, String],
      default: []
    },
    images: {
      type: [Boolean, String],
      default: true
    },
  },
  data () {
    return {
      items: []
    }
  },
  mounted() {
    this.fetchEmployees()
  },
  methods: {
    async fetchEmployees () {
      try {
        let employees = this.employees

        if (employees && employees !== 'undefined' && typeof employees === 'string') {
          employees = employees.split(',')
        }

        const response = await this.$api.location.employees(employees)

        this.items = response.employees
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<template>
  <div>
    <label class="flex items-center">
      <input
        v-model="_value"
        type="checkbox"
        :value="true"
        class="flex-shrink-0 b-checkbox appearance-none h-5 w-5 border bg-secondary rounded-sm checked:bg-action checked:border-action"
      >
      <label v-if="label || $slots.default" class="text-sm font-medium text-secondary ml-2">
        <slot>
          {{ label }}
        </slot>
      </label>
    </label>
  </div>
</template>

<script>
export default {
  name: 'BCheckbox',
  props: {
    value: {
      type: Boolean,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  }
}
</script>

<style>
  .b-checkbox:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
</style>

export default {
  components: {
    cookieFixer: {
      close: 'Close',
      browserCookies:
        'Cookies are blocked or not supported by your browser. You must enable cookies to continue.',
      cookiePrompt:
        "In order for parts of this site to function, such as logging in, we need to enable cookies. Please click the 'Enable' button below to enable cookies.",
      enable: 'Enable',
      enableCookies: 'Enable cookies',
      failedToEnable:
        "Unfortunately we couldn't enable cookies for your browser, some functions of this site, such as logging in, may not work... Click 'Close' to continue..."
    },
    cookiePermissions: {
      accept: 'Accept',
      cancel: 'Decline',
      likeCookies: 'Do you like cookies?',
      weUseCookies:
        '🍪 We use cookies to ensure you get the best possible booking experience with {location}.'
    },
    customerSelector: {
      checkIfExists:
        "We'll check if we have an account for your email address but if not, we'll just grab a few of your details.",
      createNewCustomer: 'Create an account',
      emailRequired: 'Email required.',
      findName: 'Please find your name from the list.',
      findNameOrCreate:
        "Please find your name from the list or click 'Create an account' to create a new account.",
      findCreateAccount: 'Find / Create account',
      info: 'Info',
      loggedIn: 'Logged in',
      notAcceptingNewCustomers:
        'Sorry, we are not accepting new customers at this time.',
      return:
        'Not your email? Already have an account? Click here to change your email',
      selectCustomer: 'Select Account',
      useLoggedInUser:
        "If you'd like to use the account that you're logged in with, click your name below",
      validEmailRequired: 'Valid email required',
      fields: {
        gender: {
          '-': 'Prefer not to say',
          F: 'Female',
          M: 'Male',
          NB: 'Non-binary'
        }
      }
    },
    loggedInCustomerDetail: {
      home: 'Home',
      logout: 'Logout',
      myDetails: 'My details'
    },
    moveReservationDetail: {
      rescheduling: 'Rescheduling'
    },
    payments: {
      integrations: {
        paypal: {
          newWindow:
            'The button below will open PayPal in a new window/tab. Please complete your payment in this new window/tab to confirm your transaction.'
        },
        square: {
          creditDebit: 'Credit / Debit Card'
        },
        stripe: {
          creditDebit: 'Credit / Debit Card',
          pleaseComplete:
            'Please complete the required fields above for Apple / Google Pay'
        }
      },
      paymentForm: {
        backToOptions: 'Back to payment options',
        followSteps:
          'Please follow the steps to process your payment in the popup window',
        processing: 'Processing',
        minimumAmount: 'min. transaction'
      }
    },
    reservationServiceList: {
      bookMulitple: 'To book multiple services, select them from the list.',
      cantFindService:
        "Sorry, we can't find the service that you're looking for...",
      clearExisting:
        'To book {service}, please clear your existing service selections or {link}',
      completeBelow: 'Please complete your selection below.',
      firstAvailable: 'First Available',
      multiWarning:
        'You can only book one service at a time. To book {service}, please clear your existing service selections or {link}.',
      viewAvailability: "To view availability, click '{next} »'.",
      viewServices: 'View services',
      whatService: 'What service are you looking for?',
      notBookableOnline: 'Not bookable online'
    },
    voucherDetail: {
      showingValid:
        'Showing only services and availability valid for this promo code.',
      remove: 'Remove promo code to show all availability'
    },
    passDetail: {
      showingValid:
        'Showing only services and availability valid for this pass.',
      remove: 'Remove pass to show all availability'
    },
    businessHours: {
      openNow: 'Open Now',
      open: 'Open',
      closedNow: 'Closed Now',
      closed: 'Closed',
      opensAtTime: 'Opens {time}',
      closesAtTime: 'Closes {time}',
      reopensAtTime: 'Reopens {time}',
    }
  },
  datetime: {
    days: {
      friday: 'Friday',
      monday: 'Monday',
      saturday: 'Saturday',
      sunday: 'Sunday',
      thursday: 'Thursday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday'
    },
    format: {
      date: {
        day: 'numeric',
        month: 'long',
        weekday: 'long',
        year: 'numeric'
      },
      time: {
        hour: 'numeric',
        minute: 'numeric'
      }
    },
    increments: {
      days: 'Days',
      hours: 'Hours',
      minutes: 'Minutes',
      months: 'Months',
      seconds: 'Seconds',
      weeks: 'Weeks',
      years: 'Years'
    },
    timeOfDay: {
      afternoon: 'Afternoon',
      morning: 'Morning'
    },
    labels: {
      today: 'Today',
      tomorrow: 'Tomorrow',
    }
  },
  dateTimeFormats: {
    long: {
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      month: 'short',
      weekday: 'short',
      year: 'numeric'
    },
    short: {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    }
  },
  general: {
    address: 'Address',
    addToMyAccount: 'Add to my account',
    almostFull: 'Almost Full',
    amount: 'Amount',
    anonymous: 'Anonymous',
    back: 'Back',
    balance: 'Balance',
    buy: 'Buy',
    buyNow: 'Buy now',
    bookNow: 'Book now',
    cancel: 'Cancel',
    cardNumber: 'Card number',
    charges: 'Charges',
    checkErrors: 'Check errors:',
    city: 'City',
    clear: 'Clear',
    clickHere: 'click here',
    close: 'Close',
    code: 'Code',
    comments: 'Comments',
    company: 'Company',
    confirm: 'Confirm',
    continue: 'Continue',
    correctErrors: 'Please correct the following error(s)',
    country: 'Country',
    create: 'Create',
    customer: 'Customer',
    customers: 'Customer',
    cvv: 'CVV',
    date: 'Date',
    dateAndTime: 'Date & time',
    dateOfBirth: 'Date of Birth',
    deliveredOn: 'Delivered on',
    deliveryDate: 'Delivery date',
    discount: 'Discount',
    discounts: 'Discounts',
    email: 'Email',
    error: 'Error',
    errorTitle: 'Sorry, something went wrong...',
    errorMessage: 'An error occurred while loading',
    errorMessageName: 'An error occurred while loading the {name}',
    errorPayment: 'Sorry, something went wrong while processing your payment',
    errorRequest: 'Sorry, something went wrong while processing your request',
    errorBooking: 'Sorry, something went wrong while processing your booking',
    expired: 'Expired',
    expires: 'Expires',
    expiresDate: 'expires {date}',
    expiresIn: 'Expires after {days} days',
    expiry: 'Expiry',
    fieldIsRequired: '{field} is required.',
    firstName: 'First Name',
    from: 'From',
    full: 'Full',
    gender: 'Gender',
    giftcard: 'Gift Card',
    homePhone: 'Home Phone',
    lastName: 'Last Name',
    loading: 'Loading',
    more: 'More',
    name: 'Name',
    neverExpires: 'never expires',
    next: 'Next',
    notes: 'Booking Notes',
    notesInfo: 'Provide any further details or information',
    nothingToday: 'Sorry, nothing today',
    nothingThisWeek: 'Sorry, nothing this week',
    number: 'Number',
    occupation: 'Occupation',
    on: 'on',
    or: 'or',
    other: 'Other',
    paid: 'Paid',
    pass: 'Pass',
    payment: 'Payment: Sale',
    paymentPass: 'Payment: Pass',
    paymentSale: 'Payment: Sale',
    payments: 'Payments',
    payNow: 'Pay now',
    phone: 'Mobile / Cell',
    pleaseSelectOption: '-- Please select an option --',
    pleaseWait: 'Please wait',
    plusTax: 'Plus tax',
    postcode: 'Postcode',
    previous: 'Previous',
    price: 'Price',
    products: 'Products',
    purchaser: 'Purchaser',
    rating: 'Rating',
    readMore: 'Read more',
    recipient: 'Recipient',
    refresh: 'Refresh',
    region: 'Region',
    remainder: 'Remainder',
    reservation: 'Reservation',
    reservations: 'Reservations',
    sale: 'Sale',
    save: 'Save',
    seeLess: 'See less',
    seeMore: 'See more',
    services: 'Services',
    serviceWithEmployee: '{service} with {employee}',
    serviceAtTimeWithEmployee: '{service} @ {time} with {employee}',
    showLess: 'Show less',
    showMore: 'Show more',
    spent: 'Spent',
    state: 'State',
    submit: 'Submit',
    subtotal: 'Subtotal',
    suburb: 'Suburb',
    tip: 'Tip',
    total: 'Total',
    totals: 'Totals',
    type: 'Type',
    undo: 'Undo',
    unlimitedUse: 'Unlimited use,',
    usesRemaining: '{count} use remaining, | {count} uses remaining,',
    variousAvailable: 'Various times available',
    view: 'View',
    voucher: 'Promo code',
    waitlist: 'Waitlist',
    waitTime: '{time} min wait between services',
    withAny: 'with First Available',
    withEmployee: 'with {employee}',
    withEmployeeStrong: 'with <strong>{employee}</strong>',
    withFirstAvailable: 'with First Available',
    withFirstAvailableStrong: 'with <strong>First Available</strong>',
    workPhone: 'Work Phone'
  },
  pages: {
    book: {
      availability: {
        addToWaitlist: 'Add to Waitlist',
        checking: 'Checking {month}',
        nextAvailable: 'Next available',
        noAvailability: 'Sorry, nothing today',
        noAvailabilityWithWaitlist:
          "Sorry, we don't have any availability on this date. If you'd like us to add you to our waitlist, please use the button below.",
        noSuitableTimes:
          "Can't see the time that you're after? If you'd like us to add you to our waitlist, please use the button below.",
        nothingFound: 'No availability found for the next 12 months.',
        selectDate: 'Select Date',
        title: 'Availability'
      },
      complete: {
        bookingConfirmed: 'Booking received',
        bookingConfirmedMessage: "Great, we've got your booking!",
        bookingConfirmedEmail:
          'You should receive your appointment details in an email in the next few minutes. If it\'s not in your inbox, please check your spam folder or get in touch with us.',
        formsFillInNow: 'Fill in now',
        formsCompleted: 'Completed',
        formsSentToComplete:
          'Please note, the following forms have been sent to your email address for you to fill in prior to your visit.',
        reservationNotFound: "This reservation can't be found",
        title: 'Complete'
      },
      confirm: {
        bookingDetails: 'Booking details',
        clickHere: 'click here',
        info: 'Info',
        notFinished: "You're not quite finished yet!",
        payment: 'Payment',
        servicesForThisVoucher:
          'To see only services and availability for this promo code,',
        title: 'Confirm',
        toFinaliseBooking:
          'Please complete the details below to finalise your booking.',
        voucherGiftCard: 'Promo code / Gift Card',
        applyVoucherGiftCard: 'Apply Promo code / Gift Card'
      },
      customer: {
        title: 'Customer',
        yourDetails: 'Your details'
      },
      detail: {
        serviceWithEmployee: '{service} with {employee}',
        title: 'Details'
      },
      index: {
        bookMultipleServices:
          "To book multiple services, select them from the list, then click 'Next »' to see availability",
        nextToSeeAvailability: "Great, click 'Next »' to see availability",
        selectAService: 'Please select the service from the list',
        selectAtLeastOne: 'Please select at least one service from the list',
        selection: 'Selection',
        services: 'Services'
      },
      waitlist: {
        availability: {
          finaliseWaitlist: 'Confirm Waitlist',
          addYourselfToWaitlist: 'Add yourself to the waitlist for {date}',
          dates: 'Dates',
          info:
            "Please use the calendar to select all dates that you are available, then click 'Confirm Waitlist »'.",
          partDay:
            "Please let us know what time you're able to arrive and when you need to leave.",
          selectDate: 'Select Date',
          timeToTime: '{from} to {to}',
          title: 'Waitlist - Dates',
          waitlist: 'Waitlist',
          from: 'Arrive at',
          to: 'Leave by'
        },
        breadcrumbs: {
          availability: 'Availability',
          customer: 'Customer',
          dates: 'Dates',
          waitlist: 'Waitlist'
        },
        complete: {
          expires: 'until {expires}',
          onWaitlistTitle: "We've got you on the waitlist",
          onWaitlistWithEmployee:
            "We'll let you know as soon as we can fit you in for <strong>{service} with {employee}</strong>",
          onWaitlist:
            "We'll let you know as soon as we can fit you in for <strong>{service}</strong>",
          title: "You're on the list!",
          waitlist: 'waitlist'
        },
        customer: {
          title: 'Waitlist - Customer',
          yourDetails: 'Your Details'
        },
        title: 'Waitlist'
      },
      payments: {
        pay_deposit: 'Pay Deposit',
        use_pass_deposit: 'Use pass + deposit',
        use_pass: 'Use Pass',
        pay_in_full: 'Pay In Full',
        capture_existing: 'Hold with details on file',
        capture_not_existing: 'Leave payment details',
        confirm: 'Confirm',
        pay_later: 'Pay Later',
        buy_pass: 'Buy {paymentItemName}'
      },
      button: {
        confirm_and_pay: 'Confirm & Pay',
        confirm: 'Confirm',
        confirm_and_buy_pass: 'Confirm & Buy Pass'
      }
    },
    bookapp: {
      bookapplogin: {
        forgotPassword: 'Lost your password?',
        forgotPasswordText:
          "Can't remember your password, pop over to the Password Reset page and let's get this sorted!",
        login: 'Login',
        passwordReset: 'Password Reset',
        title: 'Login'
      }
    },
    bot: {
      index: {
        title: 'Welcome'
      }
    },
    contact: {
      title: 'Where to find us'
    },
    customer: {
      details: {
        changePassword: 'Change Password',
        confirmPassword: 'Confirm Password',
        confirmPasswordError:
          'Please confirm your password. This must be the same as you enter in the new password field',
        currentPassword: 'Current Password',
        currentPasswordError: 'Please enter your current password',
        forgotPassword: 'Forgot password?',
        marketingPreferences: 'Marketing Preferences',
        newPassword: 'New Password',
        newPasswordError: 'Please enter a new password',
        passwordUpdated: 'Great, your password has been updated',
        title: 'My Details',
        update: 'Update',
        viaEmail: 'Receive marketing updates via Email',
        viaSMS: 'Receive marketing updates via SMS',
        yourDetails: 'Your Details'
      },
      form: {
        submit: {
          emailCopy: 'Email me a copy'
        },
        thanks: {
          thanksForSubmission: 'Thanks for filling in {form}'
        }
      },
      giftcard: {
        index: {
          makeBooking: 'Ready to make a booking with this gift card?',
          title: 'Gift Card',
          transactions: 'Transations'
        }
      },
      index: {
        available: 'Available',
        balances: 'Balances',
        credit: 'Credit',
        ensureFormsCompleted:
          'Please ensure that you fill in these forms, as these may be required prior to your appointment.',
        error: 'Something went wrong while loading your profile',
        expired: 'Expired',
        fillIn: 'Fill in',
        formRequests: 'Form Requests',
        giftCards: 'Gift cards',
        loyaltyPoints: 'Loyalty Points',
        noFormRequests: 'No form requests',
        noGiftCards: 'No gift cards',
        noPasses: 'No passes',
        noPastReservations: 'No past appointments',
        noPhotos: 'No photos',
        noSales: 'No sales',
        noUpcomingReservations: 'No upcoming appointments',
        noWaitlists: 'No waitlists',
        passes: 'Passes',
        past: 'Past',
        photos: 'Photos',
        reservations: 'Appointments',
        sales: 'Sales',
        serviceWithEmployeeOnDate: '{service} with {employee} on {date}',
        showingCountOfTotal: 'Showing {count} of {total}',
        upcoming: 'Upcoming',
        waiting: 'Waiting',
        waitlists: 'Waitlists',
        untilDate: 'until {expires}'
      },
      login: {
        email: {
          clickHere: 'click here',
          hiUser: 'Hi {user},',
          ifNotRedirected: 'If you are not redirected automatically,',
          loggedIn: "You're logged in, redirecting you now...",
          loggingIn: "We're logging you in...",
          title: 'Login',
          expired: {
            title: 'This link has expired',
            resend: 'Email me a new link to {email}',
            sent:
              "We've sent a new link to {email}. Please open your email and click on the link to continue.",
            home: 'Back to Home page'
          }
        },
        index: {
          cantRemember:
            "Can't remember your password, pop over to the Password Reset page and let's get this sorted!",
          connectedAccounts: 'Connected accounts',
          createAccount: 'Create account',
          enterPassword: 'Enter Password',
          login: 'Login',
          noAccount: 'Don\'t have an account?',
          signUp: 'Sign up',
          lostPassword: 'Lost your password?',
          multipleAccountSelect:
            "We've found multiple accounts for your email address ({email}), please select your name below",
          noCustomersForEmail:
            "Sorry, we don't have any accounts for that email...",
          password: 'Password',
          passwordReset: 'Password Reset',
          sendCode: 'Send me a code',
          title: 'Login',
          validateCode: 'Please enter the code from your email',
          validateEmail: 'Please enter a valid email',
          validatePassword: 'Please enter your password',
          weveSentYouACode:
            "We've sent a code to your email address ({email}), but it only lasts for 5 minutes! Please leave this window open, check your email, and enter the code below."
        }
      },
      logout: {
        title: 'Logout'
      },
      pass: {
        index: {
          makeBooking: 'Ready to make a booking with this pass?',
          title: 'Pass',
          transactions: 'Transactions'
        }
      },
      recovery: {
        passwordResetEmail:
          'A password reset email has been sent to your email. Please click the link contained in the email, or enter the code mentioned in the recovery email here',
        resetCode: 'Reset Code',
        title: 'Password Recovery'
      },
      reservation: {
        confirm: {
          confirm: 'Confirm attending',
          details: 'Details',
          exampleText: "Some quick example text to build on the card title and make up the bulk of the card's content.",
          title: 'Appointment',
          thanksForConfirming: 'Thanks for confirming your appointment',
          confirming: 'Confirming your appointment...',
          error: 'There was an issue confirming your appointment'
        },
        index: {
          anonymous: 'Anonymous',
          areYouSureCancel: 'Are you sure you want to cancel this appointment?',
          bookAgain: 'Book again',
          bookAgainText:
            'Click the button below to book this appointment again.',
          cancel: 'Cancel',
          cancelText: 'You can cancel this appointment up until {time}',
          cancelReason: 'Cancellation reason',
          cancelPleaseSelectReason: 'Please select a reason',
          cancelPleaseEnterNote: 'Please enter a note',
          changeServiceSelection: 'Change service selection',
          confirmAttending: 'Confirm Attending',
          confirmAttendingText:
            "Click the button below to let {location} know you'll be attending.",
          confirmCancel: 'Yes, Cancel',
          details: 'Details',
          editReview: 'Edit Review',
          getFeedback:
            "We'd love to get your feedback on your booking. Please leave us a review.",
          hideName: 'Display my name on the review',
          leaveReview: 'Leave Review',
          outstandingBalance:
            'This order has an outstanding balance of {remainder}, please use the buttons below to finalise payment of this sale.',
          paymentPass: 'Payment: Pass',
          paymentRequired: 'Payment required!',
          paymentSale: 'Payment: Sale',
          requestedForms: 'Requested Forms',
          reschedule: 'Reschedule',
          rescheduleText: 'You can reschedule this appointment up until {time}',
          review: 'Review',
          title: 'Appointment',
          untilDate: 'until {date}'
        }
      },
      reset: {
        confirm: 'Confirm',
        confirmPasswordError:
          'Please confirm your password. This must be the same as you enter in the password field',
        enterPasswordError: 'Please enter a password',
        loginHere: 'Login here',
        password: 'Password',
        resetPassword: 'Reset Password',
        resetSuccess: 'Great news, your password has been reset.',
        title: 'Password Reset'
      },
      sale: {
        index: {
          date: 'Date',
          details: 'Details',
          number: 'Number',
          outstandingBalance:
            'This order has an outstanding balance of {remainder}, please use the buttons below to finalise payment of this sale.',
          paymentRequired: 'Payment required!',
          title: 'Sale #{order}'
        },
        payment: {
          title: 'Payment'
        }
      },
      waitlist: {
        areYouSure: 'Are you sure you want to cancel this waitlist?',
        availability: 'Availability',
        cancelWaitlist:
          'If you no longer wish to be waitlisted for these services, you can cancel.',
        noLongerAvailableDate: "I'm no longer available on this date.",
        confirmCancel: 'Yes, Cancel',
        loadingTimes: "We're just loading the available times...",
        serviceOnDate: '<strong>{service}</strong> on {date}',
        title: 'Waitlist',
        untilDate: 'until {expires}',
        waitlist: 'waitlist',
        waitlistExpired: 'This waitlist has expired',
        waitlistSuccess: "Good news, we're able to fit you in!",
        waitlistWaiting: 'This waitlist is still waiting to become available',
        waitlistOfferUnavailable:
          'Unfortunately this appointment time has already been booked and is no longer available. Please click below to stay on the waitlist.',
        waitlistKeepOnList: 'Keep me on the list'
      },
      register: {
        title: 'Register'
      },
      success: {
        title: 'Account successfully created!',
        info: 'We\'ve made an account for {email}',
        button: 'Login Now'
      }
    },
    emails: {
      unsubscribe: {
        areYouSure:
          'Are you sure that you want to unsubscribe <strong>{email}</strong> from our mailing list?',
        sorryToSeeYouGo:
          "We're sorry to see you go, but we have successfully unsubscribed you from our mailing list!",
        title: 'Unsubscribe',
        unsubscribeFromList: 'Unsubscribe from mailing list'
      }
    },
    gift: {
      card: {
        claim: {
          claim: 'Claim',
          claimGiftCard: 'Claim Gift Card',
          doubleCheckDetails:
            "Please double check that your details are correct and click <strong>'Claim Gift Card »'</strong> to add the gift card to your <strong>{location}</strong> account.",
          hasGivenYouAGift:
            '<strong>{from}</strong> has given you a <strong>{currency}{balance} gift card</strong> for <strong>{location}</strong>.',
          luckyYouName:
            'Lucky you, <strong>{name}</strong>! Just one last step...',
          provideEmail:
            "So we can assign your gift card to your account at <strong>{location}</strong> please enter your email below. If you don't already have an account, we'll set one up for you.",
          title: 'Claim Gift Card',
          yourDetails: 'Your Details'
        },
        complete: {
          allDone: 'All done!',
          deliveredTo:
            'The gift card has been delivered to <strong>{name}</strong> ({email})',
          deliveredToDate:
            'The gift card will be delivered to <strong>{name}</strong> ({email}) on <strong>{date}</strong>',
          receiptEmailed:
            'A gift card purchase receipt has been emailed to you.',
          thanksCustomer: 'Thanks <strong>{name}</strong>,',
          title: 'Complete'
        },
        confirm: {
          almostDone: "We're almost there!",
          checkDetails:
            'Please double check the details and proceed to payment',
          giftDetails: 'Gift details',
          title: 'Confirm'
        },
        customer: {
          title: 'Customer',
          yourDetails: 'Your Details'
        },
        index: {
          amount: 'Amount',
          amountError: 'Please enter a valid amount.',
          buyGiftCardAmount: 'Buy Gift Card {amount}',
          customAmount: 'Custom Amount',
          deliveryDate: 'Delivery Date',
          deliveryDatePlaceholder: 'Now',
          design: 'Design',
          emailPlaceholder: "Recipient's email",
          enjoy: 'I hope you enjoy this Gift Card!',
          from: 'From',
          fromNameError: 'Please enter your name.',
          fromPlaceholder: 'Your name',
          giftCardDetails: 'Gift Card Details',
          locationGiftCard: '{location} Gift Card',
          message: 'Message',
          messageError: 'Please enter a message.',
          messagePlaceholder: 'Enter something',
          namePlaceholder: "Recipient's name",
          title: 'Gift Card',
          to: 'To',
          toEmailError: "Please enter a recipient's email.",
          toNameError: "Please enter the recipient's name.",
          validEmailError: 'Please enter a valid email.'
        }
      },
      index: {
        currencyVaries: '{currency} varies',
        giftCard: 'Gift Card',
        giftPass: 'Gift Pass'
      },
      pass: {
        claim: {
          claim: 'Claim',
          claimPass: 'Claim {passName}',
          doubleCheckDetails:
            "Please double check that your details are correct and click <strong>'Claim {passName} »'</strong> to add the {passName} to your <strong>{location}</strong> account.",
          hasGivenYouAGift:
            '<strong>{from}</strong> has given you a <strong>{passName}</strong> for <strong>{location}</strong>.',
          luckyYouName:
            'Lucky you, <strong>{name}</strong>! Just one last step...',
          provideEmail:
            "So we can assign your {passName} to your account at <strong>{location}</strong> please enter your email below. If you don't already have an account, we'll set one up for you.",
          title: 'Claim Pass',
          yourDetails: 'Your Details'
        },
        complete: {
          allDone: 'All done!',
          deliveredTo:
            'The gift card has been delivered to <strong>{name}</strong> ({email})',
          deliveredToDate:
            'The gift card will be delivered to <strong>{name}</strong> ({email}) on <strong>{date}</strong>',
          receiptEmailed: 'A gift purchase receipt has been emailed to you.',
          thanksCustomer: 'Thanks <strong>{name}</strong>,',
          title: 'Complete'
        },
        confirm: {
          almostDone: "We're almost there!",
          checkDetails:
            'Please double check the details and proceed to payment',
          giftDetails: 'Gift details',
          title: 'Confirm'
        },
        customer: {
          title: 'Customer',
          yourDetails: 'Your Details'
        },
        index: {
          deliveryDate: 'Delivery Date',
          deliveryDatePlaceholder: 'Now',
          description: 'Description',
          emailPlaceholder: "Recipient's email",
          from: 'From',
          fromNameError: 'Please enter your name.',
          fromPlaceholder: 'Your name',
          giftPassPrice: 'Gift {name} {price}',
          message: 'Message',
          messageError: 'Please enter a message.',
          messagePlaceholder: 'Enter something',
          nameGiftPass: 'Gift Pass: {name}',
          namePlaceholder: "Recipient's name",
          title: 'Gift Pass',
          to: 'To',
          toEmailError: "Please enter a recipient's email.",
          toNameError: "Please enter the recipient's name.",
          validEmailError: 'Please enter a valid email.'
        }
      }
    },
    index: {
      notSetUpTitle: 'Work in progress',
      notSetUpContent:
        'Sorry, Online booking is not currently available for {location}.',
      hours: 'Hours',
      noData: 'Nothing today',
      showAll: 'Show all',
      today: 'today',
      whereToFindUs: 'Where to find us',
      timetable: 'timetable'
    },
    lastMinute: {
      availableAt: 'Available at {time}',
      availableFrom: 'Available from {time}',
      serviceTimes: '{service} times'
    },
    pass: {
      complete: {
        allDone: 'All done!',
        passReadyToUse: 'Your {pass} is ready to use!',
        thanksCustomer: 'Thanks {customer},',
        title: 'Complete'
      },
      confirm: {
        almostDone: "We're almost there!",
        almostDoneMessage:
          'Please double check the details and click <b>Confirm</b>.',
        passDetails: 'Pass details',
        title: 'Confirm'
      },
      customer: {
        title: 'Customer',
        yourDetails: 'Your Details'
      },
      index: {
        buyItem: 'Buy {item}'
      }
    },
    payment: {
      error: 'Payment error',
      errorMessage:
        'The following errors occurred during the payment process. Please close this window and follow instructions on the previous window to retry your payment.',
      success: 'Success',
      successMessage:
        'Your payment was successful, please close this window and follow any instructions on the previous page.'
    },
    reviews: {
      noReviews: 'No reviews',
      showingNumOfTotal: 'Showing {items} of {total}',
      title: 'Reviews'
    },
    service: {
      detail: {
        stars: '{stars} Stars',
        ratedBy: 'Rated by {people} person | Rated by {people} people',
        priceFrom: 'Price from',
        duration: 'Duration',
        availableWith: 'Available with',
        addOns: 'Add ons'
      }
    },
    timetable: {
      timetable: 'timetable',
      nothingToday: 'Nothing today',
      selectDate: 'Select a date'
    },
    widget: {
      disabled: {
        title: 'Not available',
        message: 'Sorry, this option is not available for {location}'
      }
    }
  }
}

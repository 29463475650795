<template>
  <div>
    <div v-for="(option, key) in options" :key="key">
      <label class="flex items-center mb-2">
        <input
          v-model="_value"
          type="checkbox"
          :value="option.value || option"
          class="flex-shrink-0 b-checkbox appearance-none h-5 w-5 border bg-secondary border-primary rounded-sm checked:bg-action checked:border-action"
        >
        <label class="text-sm font-medium text-secondary ml-2">
          <slot :option="option">
            {{ option.label || option.text || option }}
          </slot>
        </label>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BCheckboxGroup',
  props: {
    value: {
      type: [Array],
      default: ''
    },
    id: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    options: {
      type: [Array, Object],
      default: () => []
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  }
}
</script>

